<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header ">
            <div class="flexH width">
                <a class="icon iconButton third" @click.prevent="$router.push('/home')">
                    <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"></font-awesome-icon>
                </a>
            </div>
            <span class="fL nowrap">填問卷拿獎勵</span>
            <div class="flexH width right">
               
                            
            </div> 
        </section>
        <!-- Main -->
        <section class="main ">
            
            <div class=" flexV width padding mt-4 mb-4">
                <div
                    class="text-center flex gap-6 item-center py-4 flex-col"
                    v-if="(!pageList || pageList.length == 0)  == true"                    
                >
                    <img  width="160" height="160" src="@/assets/icon/page-empty-content.svg" />
                   <div class="fL font-medium  cNeutral500 text-center">很抱歉，目前無可填寫問卷</div>
                </div>
                <div v-else class="flex gap-4 flex-col" >
                    <div
                        v-for="(row, index) in pageList"
                        :key="'row' + index"
                        @click="$router.push('/b1-2/' + row.campaignId)"
                    >
                        <div class="uide-mission-card">
                            <div class="uide-mission-card-top">
                                <div class="uide-mission-card-top-chip">{{ row.departmentName}}</div>
                                <div>{{ tsToDateDay(row.campaignStartTime) }}</div>
                            </div>
                            <div class="uide-mission-card-title">
                                {{ row.campaignName}}
                            </div>
                            <div >
                                {{ row.campaignDesc}}
                            </div>
                            <div class="flex gap-1 items-center">
                                <img  width="20" height="20" src="@/assets/icon/uide/coin.svg" />
                                <span>雲林幣{{ row.point }}枚</span>
                            </div>
                            <div class="flex gap-1 items-center justify-between ">
                                <ProgressBar  style="width:228px;" :progress="((row.reward_total - row.reward_remain) / row.reward_total) * 100"/>
                                <div :class="[ 'uide-mission-card-progress-btn', 'status' + row.reward_status ]">{{ getStatusName(row.reward_status) }}</div>
                            </div>
                            <div class="flex gap-1 items-center ">
                                <img  width="16" height="16" src="@/assets/icon/uide/people.svg" />
                                <span class="uide-mission-card-user">獎勵剩餘人數 {{ row.reward_remain }} 名 / 總人數 {{ row.reward_total }} 名</span>
                            </div>
                            <img src="@/assets/icon/bell-v2.svg"
                                :class="['uide-mission-card-bell', { hide: row.unread === false }]"
                            />
                        </div>
                    </div>
                    
                 
                </div>
              
               
                
            </div>
        </section>
    </div>
</template>
<script>
import { db } from "../db.js";
import moment from "moment-timezone";
import { mapState } from "vuex";
import ProgressBar from '../components/ProgressBar.vue';



export default {
    name: "NotificationSetting",
    components: {
        ProgressBar
    },
    data() {
        return {
            data: [],
            statusNames: {
                0: '快來搶獎勵',
                1: '已領取獎勵',
                2: '獎勵已搶完'
            },
            pageList: []
        };
    },
    // beforeRouteEnter(to, from, next) {
    //     // called before the route that renders this component is confirmed.
    //     // does NOT have access to `this` component instance,
    //     // because it has not been created yet when this guard is called!
    //     console.log(to, from);
    //     next((vm) => {
    //         let that = vm;
    //         if (that.user && that.user.userId) {
    //             //
    //         } else {
    //             // window.location.reload();
    //             next("/home");
    //         }
    //     });
    // },
    mounted() {
        console.log("mounted!");
        this.getQuestions()
        // this.onSnapShot();

        //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
        // this.getLatestReadTime()
        // .then(() => {
        //     this.onSnapShot();
        // });

        setTimeout(() => {
            console.log('pageList', this.pageList);
        }, 4000)
    },
    computed: {
        ...mapState(["user"]),
        // ...mapGetters({
        //     notifications: 'noti/notifications'
        // }),
        // pageList() {

        //     let list = [
        //         {id:1,department:'局處名稱',ts:1720961906,title:'拉近你我距離的縣民 App，服務上線滿意度調查問卷',desc:"despcription",unread:true,point:50,is_receive_award:true,reward_total:1500,reward_remain:600,reward_percentage:30,reward_status:0},
        //         {id:2,department:'局處名稱',ts:1720961906,title:'城鄉政令宣導民眾滿意度調查，歡迎民眾踴躍填寫',desc:"despcription",unread:false,point:50,is_receive_award:true,reward_total:1500,reward_remain:600,reward_percentage:50,reward_status:1},
        //         {id:3,department:'局處名稱',ts:1720961906,title:'城鄉政令宣導民眾滿意度調查，歡迎民眾踴躍填寫2',desc:"despcription",unread:false,point:50,is_receive_award:true,reward_total:1500,reward_remain:600,reward_percentage:70,reward_status:2},

        //     ];            
        //     return Object.values(list).sort((a, b) => {
        //         return b.ts - a.ts;
        //     });
        // }
    },
    methods: {
        getQuestions() {
            fetch(`https://dev.portal.yunlin.citycoins.cc/core/campaigns/v1/merchants/yunlin/participations/${this.user.userId}?includeNonJoined=true&includeInactive=true&publisherType=municipal`, {method: 'GET'}).then(res => res.json()).then(r => {
                this.pageList = r.campaigns.filter(c => c.campaignPrototype === 'questionnaire')
            });
            
        },
        compare(a, b) {
            if (a.ts > b.ts) {
                return -1;
            } else if (a.ts == b.ts) {
                return 0;
            } else if (a.ts < b.ts) {
                return 1;
            }
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        tsToDateDay(ts) {
            return moment(ts * 1000).format("YYYY/MM/DD");
        },
        onSnapShot() {
            let collection = db.collection(
                `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            );
            // doesn't use change to vuefire bind
            collection.onSnapshot((snapshot) => {
                this.notifications = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    this.notifications.push(
                        Object.assign({}, data, { docId: doc.id, unread: data.unread && (data.ts > this.latestReadAt) })
                    );
                });
                this.notifications.sort(this.compare);
                console.log("this.notifications: ", this.notifications)
                this.getLatestReadTime();
            });
        },
        getLatestReadTime() {
            return db.collection(`notifications/yulin/recipients/${this.user['userId']}/readTopics`)
            .doc(this.user.user.firestoreChannelId)
            .get()
            .then(snapshot => {
                if (!snapshot.exists) return;
                this.latestReadAt = snapshot.data().latestReadAt;
            });
        },
        updateUnreads(_latestReadAt) {
            if (_latestReadAt)
                this.latestReadAt = _latestReadAt;
            this.notifications.forEach(noti => {
                if (noti.ts <= this.latestReadAt)
                    noti.unread = false;
            });
        },
        readItem(docId) {
            // let collection = db.collection(
            //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let document = collection.doc(docId);
            // return db.runTransaction((transaction) => {
            //     return transaction.get(document).then((doc) => {
            //         transaction.update(document, {
            //             unread: false
            //         });
            //         return doc;
            //     });
            // });
            //NOTE: Howard's fix: use API to mark read instead of writing Firestore directly.
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    messageId: docId
                }
            }
            return this.$http(config);
        },
        readAll() {
            // let collection = db.collection(
            //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let batch = db.batch();
            // collection
            //     .get()
            //     .then((querySnapshot) => {
            //         querySnapshot.forEach((doc) => {
            //             let docRef = collection.doc(doc.id);
            //             batch.update(docRef, {unread: false});
            //         });
            //         batch.commit()
            //             .then(() => {
            //                 console.log("Read All!");
            //             })
            //     })
            //     .catch((error) => {
            //         console.log("Error getting documents: ", error);
            //     });
            //NOTE: Howard's fix: use API to mark all read instead of writing Firestore directly.
            console.log('this.user', this.user)
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-topic-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    uuid: this.user['userId']
                }
            }
            this.updateUnreads(moment().unix());//Manually mark all current notifications "read".
            this.$store.commit('noti/setUnreadCount', 0);
            return this.$http(config);
        }, 
        getStatusId(campaign) {
            return campaign.hasCompleted ? 1 : (campaign.reward_remain > 0 ? 0 : 2)
        },
        getStatusName(campaign) {
            const idx = this.getStatusId(campaign);
            return this.statusNames[idx] || "Unknown";
        },
    }
};
</script>

