<template>
    <div class="body fixed mobile popup-v2" v-show="open">
        <section
            class="popNoQA popup"
            ref="popup"
        >
            <div class="popBody">
                <!-- <a class="close" @click.prevent="close('close')">
                    <font-awesome-icon icon="fas fa-times"></font-awesome-icon>
                </a> -->
                <section v-if="!imgType == false" class="popImg">
                    <img v-if="imgType == 'success'" width="60"  height="60" src="@/assets/icon/uide/success.svg"/>
                </section>
                <section class="popMain">
                    <span class="text-lg cNeutral900 font-medium">{{title}}</span>
                    <span class=" cNeutral900 ">{{message}}</span>
                </section>
                <section class="popFooter" v-if="footer">
                    <a v-if="buttonLeftShow" class="button rounded white border-c01 solid" @click.prevent="close('btn-left')">
                        <span class="text-sm">{{buttonLeft}}</span>
                    </a>
                    <a class="button rounded border-c01 solid" @click.prevent="close('btn-right')">
                        <span class="text-sm ">{{buttonRight}}</span>
                    </a>
                </section>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: "Popup",
    props: {
        open: {
            type: Boolean,
            default: false
        },
        footer: {
            type: Boolean,
            default: false
        },
        buttonLeft: {
            type: String,
            default: "前往點數頁"
        },
        buttonRight: {
            type: String,
            default: "回首頁"
        },
        buttonLeftShow:{
            type: Boolean,
            default: true
        },
        title: String,
        message: String,
        imgType: String,
    },
    data() {
        return {
        };
    },
    mounted() {
        // this.$el.addEventListener('click', this.onClick)
        this.popup();
    },
    methods: {
        close(eventName) {
            this.$emit(eventName);
        },
        popup() {
            let target = this.$refs['popup'];
            target.style.display = "flex";
        },
        // onClick() {
        //     this.close('close');
        // }
    }
};
</script>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
