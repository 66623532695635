<!-- ToggleSwitch.vue -->
<template>
    <label class="switch">
      <input type="checkbox" v-model="checked" @change="updateValue">
      <span class="slider"></span>
    </label>
  </template>
  
  <script>
  export default {
    name: 'ToggleSwitch',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        checked: this.value,
      };
    },
    watch: {
      value(newValue) {
        this.checked = newValue;
      },
    },
    methods: {
      updateValue() {
        this.$emit('input', this.checked);
      },
    },
  };
  </script>
  
  <style>
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  </style>